import React from 'react';
import styled from "styled-components";
import {black, nunito, purple, sourceSansPro, white} from "../../Theme";
import {PlanOfActionTabs} from "../../constants/enums";
import {SaveButton} from "./PlanOfActionScreen";
import {useForm} from "react-hook-form";
import {isInReadOnlyMode} from "../../App";
import {device} from "../../constants/breakpoints";
import {PlanOfActionDesktopFooter} from './PlanOfActionFooter';

function QuestionsListCard({period, answers, activeTab, type, onSave, ...props}) {
    const {register, handleSubmit} = useForm();

    return (
        <StyledQuestionsListCard $type={type} $activeTab={activeTab}>
            <Inner>
                <QuestionsListCardRoot>

                    {type === PlanOfActionTabs.PLANOFACTION &&
                        (
                            <>
                                <ExecutiveFunctionsSummaryItem>
                                    <ItemTitle>Executieve Functies Beheerst</ItemTitle>
                                    <SummaryTagRow>
                                        {(period.scoresPerExecutiveFunction ?? [])?.filter(ef => ef.totalScore !== 0 && ef.totalScore > 15)?.map((ef, i) => (
                                            <SummaryTag key={ef.title}>
                                                <SummaryTagText>{ef.title}</SummaryTagText>
                                            </SummaryTag>
                                        ))}
                                    </SummaryTagRow>
                                </ExecutiveFunctionsSummaryItem>
                                <ExecutiveFunctionsSummaryItem>
                                    <ItemTitle>Executieve Functies Startend</ItemTitle>
                                    <SummaryTagRow>
                                        {(period.scoresPerExecutiveFunction ?? [])?.filter(ef => ef.totalScore !== 0 && ef.totalScore <= 10)?.map((ef, i) => (
                                            <SummaryTag key={ef.title}>
                                                <SummaryTagText>{ef.title}</SummaryTagText>
                                            </SummaryTag>
                                        ))}
                                    </SummaryTagRow>
                                </ExecutiveFunctionsSummaryItem>
                            </>
                        )
                    }

                    {type === PlanOfActionTabs.PLANOFACTION
                        ?
                            answers !== null && period.planOfActionQuestions.sort((a, b) => {return a.sortOrder - b.sortOrder}).map((question, index) => {
                                let answer = answers.find(a => a.questionId === question.id);
                                return  <QuestionItem key={question.id}>
                                            <QuestionText>{index + 1}. {question.title}</QuestionText>
                                            <Input defaultValue={answer ? answer.value : ""} maxLength={500} {...register(question.id)} disabled={isInReadOnlyMode()}/>
                                        </QuestionItem>
                            })
                        :
                            answers !== null && period.evaluationQuestions.sort((a, b) => {return a.sortOrder - b.sortOrder}).map((question, index) => {
                                let answer = answers.find(a => a.questionId === question.id);
                                return (
                                    <QuestionItem key={question.id}>
                                        <QuestionText>{index + 1} {question.title}</QuestionText>
                                        <Input defaultValue={answer ? answer.value : ""} maxLength={500} {...register(question.id)} disabled={isInReadOnlyMode()}/>
                                    </QuestionItem>
                                )
                            })
                    }
                    {!isInReadOnlyMode() && <SaveButton onClick={handleSubmit(handleSaveButtonClick)}/>}
                </QuestionsListCardRoot>
            </Inner>
            <PlanOfActionDesktopFooter {...props} />
        </StyledQuestionsListCard>
    );

    function handleSaveButtonClick(data) {
        Object.keys(data).forEach((questionId) => {
            let answer = answers.find(a => a.questionId === questionId);
            let answerData = {
                id: answer ? answer.id : null,
                questionId: questionId,
                periodId: period.id,
                value: data[questionId]
            }
            onSave(answerData);
        });
    }
}

const StyledQuestionsListCard = styled.div`
    display: ${({$type, $activeTab}) => $type === $activeTab ? "flex" : "none"};
    flex-direction: column;
    width: 100%;
    max-width: 590px;
`;

const Inner = styled.div`
    background: ${white};
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 3px 3px 0 0;
    padding: 38px 67px;

    @media screen and ${device.tablet} {
        padding: 38px 47px;
    }

    @media screen and ${device.mobileL} {
        padding: 20px 23px;
    }
`;

const QuestionsListCardRoot = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

const ExecutiveFunctionsSummaryItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const SummaryTagRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`;

const SummaryTag = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${purple};
    border: 1px solid #5E4187;
    box-sizing: content-box;
    border-radius: 3px;
    padding: 0 15px;
`;

const SummaryTagText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${white};
`;

const ItemTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
`;

const QuestionItem = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
`;

const QuestionText = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
    margin-bottom: 10px;
`;

const Input = styled.textarea`
    resize: none;
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
    padding: 5px 9px;
    width: 100%;
    border: 1px solid #E2E2EB;
    border-radius: 2px;
    height: 84px;
`;

export default QuestionsListCard;